import 'isomorphic-fetch'
import _ from 'lodash'
import { getEnv } from './functions'
import axios from 'axios'
var Toastr = require('toastr/build/toastr.min.js')
// export var httpProtocol = `${window.location.protocol}//`
export var httpProtocol = 'https://'

require('toastr/build/toastr.css')

export function checkStatus(response) {
  return response
}

export function parseJSON(response) {
  return response.json()
}

export function downloadFile(e, fname, url) {
  if (e) e.preventDefault()

  var req = new XMLHttpRequest()
  req.open('GET', url, true)
  req.responseType = 'blob'
  req.onreadystatechange = function() {
    if (req.readyState === 4 && req.status === 200) {
      var filename = fname
      if (typeof window.chrome !== 'undefined') {
        // Chrome version
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(req.response)
        link.download = fname
        link.click()
      } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE version
        var blob = new Blob([req.response], { type: 'application/pdf' })
        window.navigator.msSaveBlob(blob, filename)
      } else {
        // Firefox version
        var file = new File([req.response], filename, {
          type: 'application/force-download',
        })
        window.open(URL.createObjectURL(file))
      }
    }
  }
  req.send()
}

export function sendReportToEmail(e, url) {
  if (e) e.preventDefault()

  var req = new XMLHttpRequest()
  req.open('GET', url, true)
  req.onreadystatechange = function(res, wer) {
    if (req.readyState === 4 && req.status === 200) {
      const response = JSON.parse(req.response)
      Toastr.success(response.message)
    }
  }
  req.send()
}

/**
 * A utility to call a restful service.
 *
 * @param url The restful service end point.
 * @param config The config object of the call. Can be null.
 * @param request The request action.
 * @param onRequestSuccess The callback function to create request success action.
 *                 The function expects response json payload as its argument.
 * @param onRequestFailure The callback function to create request failure action.
 *                 The function expects error as its argument.
 */
export function buildQueryString(params) {
  params = _.extend({}, params)
  return _.map(params, function(val, key) {
    if (typeof val === 'object') {
      return _.map(val, function(v, k) {
        return key + '[' + k + ']=' + v
      }).join('&')
    } else {
      return key + '=' + val
    }
  }).join('&')
}

export function buildUrlWithQueryString(url, params) {
  var paramsQueryString = buildQueryString(params)
  if (_.isEmpty(paramsQueryString)) {
    return url
  } else {
    if (url.indexOf('?') >= 0) {
      return url + '&' + paramsQueryString
    } else {
      return url + '?' + paramsQueryString
    }
  }
}

const apiURLs = {
  dev: 'api-dev.brickspms.click',
  staging: 'api.staging.brickspms.com',
  newstaging: 'api-staging.brickspms.click',
  production: 'api.brickspms.com',
  productionnew: 'api.brickspms.com',
  localhost: process.env.REACT_APP_LOCALHOST_API_URL,
}

const envURLs = {
  dev: 'web-dev.brickspms.click',
  staging: 'staging.brickspms.com',
  newstaging: 'web-staging.brickspms.click',
  production: 'brickspms.com',
  productionnew: 'brickspms.com',
  localhost: '',
}

export function getApiUrl(protocol = httpProtocol) {
  console.log('httpProtocol', httpProtocol)
  let env = getEnv()
  const version = 'v2'
  const apiURL = apiURLs[env]
  window.envurl = envURLs[env]
  window.apiURL = `${protocol}${apiURL}/${version}`

  return window.apiURL
  //window.apiURL = protocol + 'api.staging.leasing.clicksandbox.com'

  // if (env === 'staging') {
  //   window.envurl = 'staging.leasing.clicksandbox.com'
  //   window.apiURL = protocol + 'api.staging.leasing.clicksandbox.com'
  //   return protocol + 'api.staging.leasing.clicksandbox.com'
  // } else if (env === 'brickspms') {
  //   const version = 'v2'
  //   window.envurl = 'brickspms.com'
  //   window.apiURL = `${protocol}api.staging.leasing.clicksandbox.com/${version}`
  //   return window.apiURL
  // } else if (env === 'qa') {
  //   const version = 'v2'
  //   window.envurl = 'staging.brickspms.com'
  //   window.apiURL = `${protocol}api.staging.brickspms.com/${version}`
  //   return window.apiURL
  // } else if (env === 'localhost') {
  //   window.envurl = ''
  //
  //   // return protocol+'api.dev.brickspms.com';
  //   const version = 'v2'
  //   window.apiURL = `${protocol}api.staging.brickspms.com/${version}`
  //   return window.apiURL
  //   // const version = 'v2'
  //   // window.apiURL = `${protocol}api.dev.brickspms.com/${version}`
  //   // return window.apiURL
  //   // const version = 'v2'
  //   // window.apiURL = `${protocol}api.dev.brickspms.com/${version}`
  //   // return window.apiURL
  //
  //   // const version = 'v2'
  //   // window.apiURL = `${protocol}192.168.1.136:4000/${version}`
  //   // return window.apiURL
  //
  //   // const version = 'v2'
  //   // window.apiURL = `${protocol}192.168.1.22:4000/${version}`
  //   // return window.apiURL
  //
  //   // window.apiURL = protocol+'api.qa.leasing.clicksandbox.com';
  //   // return protocol+'api.qa.leasing.clicksandbox.com';
  //   // window.apiURL = protocol+'192.168.1.128:4000';
  //   // return protocol+'192.168.1.128:4000';
  //
  //   //return protocol+'api.qa.leasing.clicksandbox.com';
  //
  //   //return protocol+'api.staging.leasing.clicksandbox.com';
  // } else if (env === 'local.dev.leasing') {
  //   window.envurl = ''
  //   const version = 'v2'
  //   window.apiURL = `${protocol}api.dev.brickspms.com/${version}`
  //   return window.apiURL
  // } else {
  //   const version = 'v2'
  //   window.envurl = 'dev.brickspms.com'
  //   window.apiURL = `${protocol}api.dev.brickspms.com/${version}`
  //   return window.apiURL
  //   //return protocol+'api.staging.leasing.clicksandbox.com';
  // }
}

export function getEnvironment() {
  //console.log('getEnv', getEnv())

  let appUrl = window.location.hostname.split('/')[0]

  if (appUrl === 'staging.leasing.clicksandbox.com') {
    return 'staging'
  } else if (appUrl === 'brickspms.com') {
    return 'staging'
  } else if (appUrl === 'www.brickspms.com') {
    return 'staging'
  } else if (appUrl === 'web-staging.brickspms.click') {
    return 'newstaging'
  } else if (appUrl === 'brickspms.click') {
    return 'productionnew'
  } else if (appUrl === 'staging.brickspms.com') {
    return 'qa'
  } else if (appUrl === 'localhost') {
    return 'localhost'
  } else {
    return 'dev'
  }
}

export function buildDownloadUrl(path, params) {
  const API_ROOT = getApiUrl(httpProtocol)
  const url = API_ROOT + path
  const slug = getCompanySlug()
  params = { ...params, auth_token: loadIdToken(), slug }
  return buildUrlWithQueryString(url, params)
}

export function api(path, { config: apiConfig, params }) {
  const API_ROOT = getApiUrl(httpProtocol)
  const idToken = loadIdToken()

  const config = {
    ...apiConfig,
    headers: {
      Accept: 'application/json',
      'cache-control': 'reload',
      'Content-Type': 'application/json',
      'Auth-Token': idToken,
      slug: localStorage.getItem('company'),
    },
  }

  let url = API_ROOT + path
  url = buildUrlWithQueryString(url, params)

  return fetch(url, config)
}

export function callApi(
  path,
  params,
  config,
  request,
  onRequestSuccess,
  onRequestFailure,
) {
  const API_ROOT = getApiUrl(httpProtocol)
  const idToken = loadIdToken()

  config = {
    ...config,
    headers: {
      Accept: 'application/json',
      'cache-control': 'reload',
      'Content-Type': 'application/json',
      'Auth-Token': idToken,
      slug: localStorage.getItem('company'),
    },
  }

  let url = API_ROOT + path
  url = buildUrlWithQueryString(url, params)

  return dispatch => {
    dispatch(request())

    return fetch(url, config)
      .then(checkStatus)
      .then(parseJSON)
      .then(json => {
        if (!json.success) {
          // (response.status < 200 || response.status > 300)
          json.error && Toastr.error(json.error)
          dispatch(onRequestFailure(json))
        } else {
          json.message && Toastr.success(json.message)
          dispatch(onRequestSuccess(json))
        }
      })
      .catch(error => {
        const exceptionMessage = {
          success: false,
          error: 'Something went wrong!',
        }
        dispatch(onRequestFailure(exceptionMessage))
      })
  }
}

export function actionCableInitialize() {
  window.ActionCable.startDebugging()
  window.App = {}
  window.App.cable = window.ActionCable.createConsumer(
    getApiUrl('ws://') + '/cable',
  ) // this url might be different
}

export const ID_TOKEN = 'id_token'

export function setIdToken(idToken, user_id) {
  localStorage.setItem(ID_TOKEN, idToken)
  localStorage.setItem('USERID', user_id)

  axios.defaults.headers.common['Auth-Token'] = idToken
  axios.defaults.headers.common['slug'] = localStorage.getItem('company')
}

export function removeIdToken() {
  localStorage.removeItem(ID_TOKEN)
  localStorage.removeItem('USERID')
  axios.defaults.headers.common['Auth-Token'] = null
}

export function loadIdToken() {
  const token = localStorage.getItem(ID_TOKEN)
  axios.defaults.headers.common['Auth-Token'] = token
  axios.defaults.headers.common['slug'] = localStorage.getItem('company')
  return token
}

export function getCompanySlug() {
  return localStorage.getItem('company')
}

export function getCompanyId() {
  return localStorage.getItem('companyID')
}

/**********************
  Handle Actions
***********************/

// send request to API
export function sendRequest(url, config, params, actionType) {
  return callApi(
    url,
    params,
    config,
    () => requestPending(actionType),
    response => requestFulfilled(actionType, response.data),
    error => requestRejected(actionType, error),
  )
}

// on request pending run pending action
function requestPending(type_name) {
  const type = `${type_name}_LOAD_REQUEST`
  return { type }
}

// on request success run Fulfilled action
function requestFulfilled(type_name, payload) {
  const type = `${type_name}_LOAD_SUCCESS`
  return {
    type,
    payload,
  }
}

// on request failed run Rejected action
function requestRejected(type_name, payload) {
  const type = `${type_name}_LOAD_FAILURE`
  return {
    type,
    payload,
  }
}
